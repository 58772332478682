<template>
<div class="page-recuperar-eliminados">
  <v-container>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </v-container>

  <v-container fluid fill-height>
    <v-row :justify="'center'" :align="'center'">
      <v-col sm='12'>
        <base-material-card color="primary" icon="library_books" title="Recuperar Eliminados" class="elevation-1 px-5 py-3">

      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md>
              <v-row>                
                <v-col sm="3" md="3" lg="3">
                    CONTADOR: {{ contador }} <br>
                    CONT VENTAS: {{ventas_cont}}<br>  
                    VENTAS MATRIZ: {{ventas_matriz}} <br>                  
                    RECUPERADAS: {{recuperadas}}
                </v-col>                                   
                <v-col sm="4" md="4" lg="4">
                    TOTAL: {{ total_eliminados }}  
                                      
                </v-col>
                <v-col sm="3" md="3" lg="3">
                    <v-btn color="success" @click.native="get_eliminados()">
                        <v-icon>done</v-icon> Buscar Ventas
                    </v-btn>
                </v-col>
            </v-row>              

          </v-container>
        </v-form>
      </v-card-text>
      
      <v-card-text>
        <div style="text-align:center"><h5>{{ nombreEmpleado }}</h5></div>
        <v-row>
            <v-col sm="12" lg="12" md="12">
                <h5>Ventas</h5>
                <div id="tableEfectivo" style="height: 70vh; overflow-y: scroll; overflow-x: scroll;">
                    <exportar-excel :data="ids_ventas" :exportFields="encabezadoLayoutExportar" name="VentasRecuperadas.xlsx">
                        <v-btn id="botonExportar" fdark x-small color="green darken-2" title="Exportar a Excel" v-tippy>
                        <v-icon dark>mdi-table</v-icon>
                        </v-btn>
                    </exportar-excel>&nbsp;
                    <v-client-table :data="ids_ventas" :columns="columns" :options="options">
                        <template slot="noResults">
                            <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                        </template>
                        
                    </v-client-table>
                </div>
                
            </v-col>
        </v-row>        
        


      </v-card-text>


        </base-material-card>
      </v-col>
    </v-row>
  </v-container>   

  <!-- MODAL CAJA -->
    <v-dialog v-model="modal_caja" max-width="35%">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">edit</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Caja a la que se moverán ventas facturadas</strong>
                    </span>
                </v-card>                
            </v-card-title>
            <v-card-text>                
                    <v-container grid-list-md>
                        <br>
                        <v-form ref="formcaja" lazy-validation>
                        <v-row>
                            <v-col sm="12" md="12" lg="12">
                                <v-text-field label="Ingrese Letra de Caja" 
                                    @input="caja_seleccionada = caja_seleccionada.toString().toUpperCase()"
                                    maxlength=1
                                    ref="ingresaCaja"
                                    v-model="caja_seleccionada" 
                                    :rules="[rules.required, valida_caja]">
                                </v-text-field>
                            </v-col>
                        </v-row>  
                        </v-form>                      
                    </v-container>                
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click.native="modal_caja=false">
                    <v-icon>cancel</v-icon> Cerrar
                </v-btn>
                &nbsp;&nbsp;
                <v-btn color="success" @click.native="saveRetiro()">
                    <v-icon>done</v-icon> Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</div>
</template>

<script>

export default {
  components: {
  },
  watch: {
    model_filters: {
      handler(val) {
        this.$nextTick(() => {
          this.items = [];
          this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        });
      },
      deep: true
    },
    search_empleado(val) {
        //Si no ha escrito 2 letras o mas
        if (val == null || val.length<2 ) return
        // Si todavía esta buscando
        if (this.isLoading) return
        this.isLoading = true
        let data = {
                    "selector": {
                        "type":"user",
                        "nombre": {"$regex": "(?i)" + val.toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")},
                        "estatus": "Activo"
                    },
                    // "sort": ["_id"],
                };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/_users/_find/',data)
                .then(response => {
                    if (response.data.docs.length > 0){
                        this.empleados = response.data.docs;
                    }else
                        this.empleados = [];
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener los empleados.",
                        footer: ""
                    });
                }).then(()=>{ this.isLoading=false; });
      },


  },
  mounted: function() {
    if (!this.verificaPermiso('t.cortes.cortes')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },
  created: function() {
    this.model = this.clean_model();
    var today = window.moment().format("YYYY-MM-DD");
    this.model.fecha_de = today;        
  },
  data() {
    return {
        encabezadoLayoutExportar: {
        "Venta":"venta",
        "Folio Anterior":"folio_anterior",
        "Folio Nuevo":"folio_nuevo"        
      },
        ventas_matriz:0,
        recuperadas:0,
        ventas_cont:0,
        contador: 0,
        total_eliminados:0,
        ids_ventas:[],
        cajas_autoincrementable:[],
        caja_seleccionada:"",
        modal_caja: false,
        series_empleado:[],      
        series_empleado_array:[],  
        model_corte:{},        
        nombreEmpleado:"",
        total_efectivo:0,
        total_voucher:0,
        total_transferencia:0,
        total:0,
        total_no_facturada:0,
        total_facturada:0,        
        efectivo_items:[],
        columns: ["venta","folio_anterior","folio_nuevo"],
        options:{
            perPage: 10,
            pagination: {
                show: true
                },
            headings:{
                "venta":"Id Venta",
            },
        },
        sucursales:[],
        menu1:false,
        menu2:false,
        menu3:false,
        menu4:false,
      search_empleado:'',
      search_marca: null,
      tipos_precios: [],
      categorias:[],
      marcas:[],
      empleados:[],
      articulos_proveedores:[],
      fab: [],
      isLoading: false,
      isLoadingSeries: false,
      articulos_encontrados: [],
      id_cliente: "",

      search: "",
      model_filters: "",

      tmp_fecha: "",
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Cortes",
          disabled: true,
          href: ""
        },
        {
          text: "Retirar Caja",
          disabled: true,
          href: ""
        }
      ],

      modal: false,
      modal_articulos: false,
      update: false,
      model: {},
      rules: {
        required: v => !!v || "Este campo es requerido",
        decimal: value => {
          const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
          return pattern.test(value) || "Número decimal no válido";
        },
        entero: function(numero) {
          const pattern = /^[\d]*$/;
          return pattern.test(numero) || "Debe ser un número entero";
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Correo electrónico no valido";
        },
        rfc: v =>
          (v && v.length >= 9 && v.length < 15) || "RFC debe tener al menos 9 caractéres y 14 caractéres"
      },
      items: []
    }
  },
  methods: {
    get_sucursal: function(id_sucursal){
        var filtro = this.sucursales.find(e => e._id == id_sucursal);
        if(filtro){
            return filtro;
        } else{
            return false;
        } 

    },
    valida_caja: function(val){
        const pattern = /^[\a-zA-Z]*$/;
        if (val != null && val != "" && !pattern.test(val)) {
            return "Letra no válida";
        }

        //Si filtró por serie/caja que no sea la misma caja
        if(this.model.serie.length > 0){
            if(this.model.serie.includes(val)){
                return "No puede ser la misma caja a la que se hace retiro";
            }
        } else {//Si no filtró por serie, que no sea ninguna de las del empleado
            if(this.series_empleado_array.includes(val)){
                return "No puede ser la misma caja a las que se hace retiro";
            }
        }
        
        return true;
    },

    validaRetiro: function(){
        this.caja_seleccionada = "";
        if(this.total_facturada > 0){
            //mostrar modal y solicitar la caja a donde se enviaran las ventas facturadas
            this.modal_caja = true;
            setTimeout(() => {
                this.$nextTick(() => this.$refs.ingresaCaja.focus());
            }, 100);
        } else {
            this.saveRetiro();
        }
    },
   
    setNombreEmpleado: function(){
        var self = this;
        var filtro = this.empleados.find(function(e){
            return e.name == self.model.id_empleado;
        });

        if(filtro)
            self.nombreEmpleado = filtro.nombre;
        else
            self.nombreEmpleado = "";
    },
    validaCantidadFloatCero: function (value) {
        const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
        if (value != null && value != "" && !pattern.test(value)) {
            return "Cantidad no válida"
        } else if (value != null && value != "") {
            if (parseFloat(value) <= 0)
                return true;
        }
        return true;
    },
    
    filtrar_series:function(){
        //console.log("Series: ", this.model.serie);
        this.buscar(this.model.serie);
    },
   
    //Llenamos el arreglo con la caja, anio y su folio menor, para luego en saveretiro regresar el folio autoincrementable de la caja
    busca_menor_autoinc: function(cajas_autoincrementable, venta){
        var self = this;
        var encontrado = 0;
        for(var k in cajas_autoincrementable){
            var auto = cajas_autoincrementable[k];
            var anio = parseInt(self.parse_date_anio(venta.fecha));
            if( auto['caja'] == venta.caja && auto['anio'] == anio){
                encontrado = 1;
                if(auto['folio'] > parseInt(venta.folio_int)){//Solo si es menor se reemplaza
                    auto['folio'] = parseInt(venta.folio_int);
                }
                break;
            }
        }
        if(encontrado == 0){ //Si no se encontró se agrega uno nuevo
            var model = {
                "id_sucursal": venta.sucursal._id,
                "caja":venta.caja,
                "anio": parseInt(self.parse_date_anio(venta.fecha)),
                "folio": parseInt(venta.folio_int)
            }
            cajas_autoincrementable.push(model);
        }
    },
    onlyUnique: function(value, index, array) {
      return array.indexOf(value) === index;
    },
    parse_date_time_mx(date) {
      if (!date) return null;
      return window.moment(String(date)).format("DD-MM-YYYY HH:mm");
    },
    parse_date_anio(date) {
      if (!date) return null;
      return window.moment(String(date)).format("YYYY");
    },
   
    formatNumber: function (x) {
      if (x == undefined) return 0;
      x = x.toString();
      var pattern = /(-?\d+)(\d{3})/;
      while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
      return x;
    },
    //recuperar ventas eliminadas
    get_eliminados: function(){
        var ventas_recuperadas = 0;
        var self = this;
        self.ventas_matriz = 0;
        self.ventas_cont = 0;
        self.recuperadas = 0;
        /*window.axios
            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_changes/')
            .then(async response => {
                if (response.data.results.length > 0){
                    const primeros10Elementos = response.data.results.slice(0, 10);
                    */

                //fetch("eliminados_bodeguita.json")
                fetch("_changes.json")
                .then(function (response) {
                    return response.json();
                }).then(async function (data_eliminados) {
                    
                    var filtro_eliminados = data_eliminados.results.filter(e => e.deleted == true && e.id.indexOf("_desi")==-1 && e.id.indexOf("func")==-1 );
                    self.total_eliminados = filtro_eliminados.length;
                    var primeros10Elementos = filtro_eliminados.reverse();
                    self.contador = 0;
                    for(var k in primeros10Elementos){
                        self.contador += 1;
                        var eliminado = primeros10Elementos[k];

                        if(eliminado.deleted == true){                            
                            var id_eliminado = eliminado.id;
                            //console.log(eliminado);                            

                            await window.axios
                            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/'+id_eliminado+'?revs=true&open_revs=all', {headers: {Accept: "application/json"}})
                            .then(async response => {
                                //console.log("Revisiones: ", response.data);
                                //var deletedDoc = JSON.parse(response.data.substring(response.data.indexOf("{"), response.data.lastIndexOf("}") + 1));
                                var deletedDoc = response.data[0].ok;
                                //console.log(deletedDoc)

                                if(deletedDoc._revisions.ids.length > 1){
                                    //Version anterior al eliminado
                                    var preDeleteRevisionNumber = (deletedDoc._revisions.start - 1) + "-"+ deletedDoc._revisions.ids[1];
                                    var lastRevision = deletedDoc._rev;                                    

                                    await window.axios
                                    .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/'+id_eliminado+'?rev='+preDeleteRevisionNumber)
                                    .then(async response => {
                                        if(response.data){
                                            var venta = response.data;               
                                            //console.log("VENTA: ", response.data);
                                            if(venta.type && venta.type == "ventas"){
                                                self.ventas_cont += 1;
                                                //console.log(venta);
                                            }
                                            
                                            if(venta.type && venta.type == "ventas" && ["Cancelada","Finalizada","Facturada","Factura Global"].includes(venta.estatus) && venta.fecha.indexOf("2023-09-21") != -1 && venta.sucursal.nombre == "MATRIZ"){
                                                self.ventas_matriz += 1;
                                                
                                                console.log("VENTA: ", response.data);
                                                delete(venta["_rev"]);

                                                /*var anio = self.parse_date_anio(venta.fecha);
                                                var dataConsecutivo={
                                                    "id_sucursal": venta.sucursal._id, //requerido
                                                    "anio":  anio, //requerido
                                                    "caja": "X", //requerido                              
                                                };
                                                await window.funciones.getConsecutivoCaja(dataConsecutivo)
                                                .then( async resultConsecutivo =>{

                                                    var ticket_anterior = venta.ticket.toString().toUpperCase();
                                                    venta['ticket_anterior'] = ticket_anterior;
                                                    venta.folio = resultConsecutivo.toString();
                                                    venta.folio_int = parseInt(resultConsecutivo);
                                                    venta.ticket = ticket_anterior.toString().substring(0,6) + "X" + resultConsecutivo.toString();
                                                    venta.caja = "X";
                                                    venta.caja_venta = "X";

                                                    await window.axios
                                                        .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + id_eliminado + '?conflicts=true', venta)
                                                        .then(rupdate => {
                                                            //console.log("Se movio venta facturada "+ticket_anterior+" a nueva caja "+self.caja_seleccionada);
                                                            self.recuperadas += 1;
                                                            self.ids_ventas.push({"venta":venta._id, "folio_anterior":ticket_anterior, "folio_nuevo":venta.ticket});
                                                        })
                                                        .catch(error => {
                                                            console.log("Error al guardar nuevo folio de venta facturada: ", error);                                                            
                                                        });
                                                }).catch( err => {
                                                    console.log("Error al obtener consecutivo: ", err);                                                    
                                                });*/

                                                //regresamos la venta
                                                /*await window.axios
                                                .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/'+id_eliminado+'?conflicts=true', venta)
                                                .then(response => {
                                                    console.log(response);
                                                    self.recuperadas += 1;                                    
                                                }).catch(error => {
                                                    //console.log("error 3: ", error);
                                                });*/
                                            } else if(venta.type && venta.type == "ventas") {
                                                console.log("VENTA SIN CONDICION: ",venta);
                                            }
                                        }

                                    }).catch(err => {
                                        //console.log("error 2: ", err);
                                    })

                                }                                
                            
                            }).catch(error => {
                                //console.log("error 1: ", error);
                            });

                        }
                        
                    }
                }).catch(function (error) {
                    console.log("Hubo un error:", error);
                });                                                       
                    
             /*}
                    
            }).catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener las cambios.",
                    footer: error
                });
            });*/
                
    },
    //ELIMINAR DELETED DOCS DEFINITIVAMENTE
    purgar_eliminados: function(){
        var ventas_recuperadas = 0;
        var self = this;
        self.ventas_matriz = 0;
        self.ventas_cont = 0;
        self.recuperadas = 0;
        
                fetch("_cambios_bodeguita.json")
                .then(function (response) {
                    return response.json();
                }).then(async function (data_eliminados) {
                    
                    var filtro_eliminados = data_eliminados.results.filter(e => e.deleted == true && e.id.indexOf("_desi")==-1 && e.id.indexOf("func")==-1 );
                    self.total_eliminados = filtro_eliminados.length;
                    var primeros10Elementos = filtro_eliminados.slice(0,10);
                    self.contador = 0;
                    for(var k in primeros10Elementos){
                        self.contador += 1;
                        var eliminado = primeros10Elementos[k];

                        if(eliminado.deleted == true){                            
                            var id_eliminado = eliminado.id;
                            //console.log(eliminado);                            

                            await window.axios
                            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/'+id_eliminado+'?revs=true&open_revs=all', {headers: {Accept: "application/json"}})
                            .then(async response => {
                                var deletedDoc = response.data[0].ok;
                                //var deletedDoc = JSON.parse(response.data.substring(response.data.indexOf("{"), response.data.lastIndexOf("}") + 1));
                                if(deletedDoc._revisions.ids){
                                    console.log("Deleted DOC: ",deletedDoc)

                                        let data = {};
                                        data[id_eliminado] = [deletedDoc._rev];                                        

                                        await window.axios.create({
                                            withCredentials: false
                                        }).post('https://labodeguitadeguadalupe.com:5984/bodeguita/_purge', data, 
                                            {
                                                headers: {
                                                    Authorization: "Basic YWRtaW46YWRtaW4xMjMq"
                                                }
                                            }
                                        ).then(async response => {
                                            if(response.data){
                                                console.log("RESPONSE: ", response.data);                                                
                                            }

                                        }).catch(err => {
                                            console.log("error 2: ", err);
                                        })
   
                                }                                                                                               
                            
                            }).catch(error => {
                                console.log("error 1: ", error);
                            });

                        }
                        
                    }
                }).catch(function (error) {
                    console.log("Hubo un error:", error);
                });                                                       
                    
          
    },
    get_sucursales: function () {
        let data = {
            "selector": {
                "type":"sucursales",
                "estatus": "Activo"
            },
            "fields": [
                "nombre", "_id","nombre","clave"
            ]
        };

        window.axios
            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
            .then(response => {
                this.sucursales = [];                
                if (response.data.docs.length > 0){
                    this.sucursales = response.data.docs;                    
                }
                    
            })

            .catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener las sucursales.",
                    footer: ""
                });
            });
    },
    parse_date(date) {
      if (!date) return null;
      return moment(String(date)).format("YYYY-MM-DD");
    },
    valida_fecha: function(fecha) {
      if (fecha == "Invalid date")
        return "Fecha no válida."
      else
        return true;
    },
    valida_time: function(time) {
      if(time!=null && time.toString()!=""){
            if(time.toString().length != 5)
                return "Hora no valida1";
            if(!time.match(/^\d{2}:(?:[0-5]\d)$/))
                return "Hora no valida2";
            var time_arr = time.split(":");
            if(time_arr.length!=2){
                return "Hora no valida3";
            }else{
                if(isNaN(time_arr[0]) || isNaN(time_arr[1])){
                    return "Hora no valida4";
                }
                if(parseInt(time_arr[0])<24 && parseInt(time_arr[1])<60)
                {
                    return true;
                } else{
                    return "Hora no valida5";
                }
            }
      }
      return true;

    },
    validaCantidadEntera: function(value) {
      const pattern = /^[\d]*$/;
      if (value != null && value != "" && !pattern.test(value)) {
        return "Número no válido"
      } else
        return true;
    },
    clean_model: function() {
      return {        
        fecha_de: '',        
        canceladas: "0",
        serie:[],
        sucursal:""        
      };
    },
    clean_model_corte: function() {
      return {        
        "type": "corte_caja",
        "tipo": "corte_general",
        "caja": "GENERAL", //caja de la ultima venta
        "cajero": "", //nombre del cajero
        "cajero_id": "", //email del cajero
        "sucursal": {}, 
        "fecha": "", //Fecha del día seleccionado
        "fecha_corte_general":"", 
        "supervisor": "", //usuario logueado ["_id", "nombre", "email"]
        
        "billetes_1000": 0,
        "monto_1000": 0,
        "billetes_500": 0,
        "monto_500": 0,
        "billetes_200": 0,
        "monto_200": 0,
        "billetes_100": 0,
        "monto_100": 0,
        "billetes_50": 0,
        "monto_50": 0,
        "billetes_20": 0,
        "monto_20": 0,
        "monto_monedas": 0,
        "billetes_total": 0,
        
        "efectivo_caja": 0, 
        "efectivo_retirado": 0,
        "restante_en_caja": 0, //restante efectivo

        "cantidad_voucher": 0,
        "monto_voucher": 0, //monto voucher retirado
        "cantidad_transferencia": 0,
        "monto_transferencia": 0,//monto transferencias retirado
        "monto_total": 0,  //monto total retirado

        //Exclusivos del corte general
        "faltante":0,
        "sobrante":0,
        "observaciones":"",
      };
    },    



  }
}
</script>
